<template>
	<div>
		<RecruitmentForm />
	</div>
</template>

<script>
import RecruitmentForm from '@/components/templates/RecruitmentForm';

export default {
	components: {
		RecruitmentForm
	}	
}
</script>